<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="org">
      <GroupHeader :group="org" />
      <v-alert v-if="status && status.connected" tile prominent type="success">
        <p>{{$t('events.welcome.joined-message', {event: org.name})}}</p>
        <p>
          <span v-if="status.verification_code">{{$t('events.welcome.joined-code', {code: status.verification_code})}}</span>
        </p>
        <p v-if="status.reg_name">
          <i18n path="events.welcome.reg-name">
            <strong >{{status.reg_name}}</strong>
          </i18n>
        </p>
        <p>
          <v-btn :to="{ name: 'organizationWelcome', params: {id: org.id}}" class="mr-2">Manage</v-btn>
        </p>
      </v-alert>

      <vue-markdown v-if="org.description" class="markdown px-4 pt-8" :html="false">{{org.description }}</vue-markdown>
      <div v-if="tenant.id == 'cofi'">
        <v-card-title class="subtitle">My Sub Challenges</v-card-title>
        <div v-if="status && status.connected">
          <v-alert v-if="status.is_owner" type="info" tile class="mb-0">
            <p>You are the admin of this organization. You are now viewing the organization page as a member.</p>
            <p>
              <v-btn :to="{ name: 'orgmanagerView', params: { id: this.org.id } }">Open Admin Dashboard</v-btn>
            </p>
          </v-alert>
          <v-card-text>
            Create your own challenge and invite your co-workers to join for a healthy bit of competition.
          </v-card-text>
          <v-card-text>
            <v-btn v-if="!status.is_owner" :color="org.color||'primary'" :dark="$helpers.isDarkColor(org.color || 'primary')" :to="{ name: 'organizationChallengesCreate', params: {id: org.id}}" class="mr-2">Start a Challenge</v-btn>
            <v-btn outlined :color="org.color||'primary'" :to="{ name: 'organizationChallenges', params: {id: org.id}}">My Sub Challenge</v-btn>
          </v-card-text>
        </div>
        <v-card-text v-else>
          Join this group first in order to create and join sub challenges.
        </v-card-text>
      </div>

      <div v-if="org.events && org.events.length >= 0">
        <!-- <v-card-title class="subtitle">Events and challenges by {{org.name}}</v-card-title> -->
        <div v-if="activeEvents && activeEvents.length">
          <v-card-title>Active Events</v-card-title>
          <EventGrid :items="activeEvents" icon="fa-pencil-alt" :minimal="false" class="mx-4 mb-4"></EventGrid>
        </div>

        <div v-if="upcomingEvents && upcomingEvents.length">
          <v-card-title>Upcoming Events</v-card-title>
          <EventGrid :items="upcomingEvents" icon="fa-pencil-alt" :minimal="false" class="mx-4 mb-4"></EventGrid>
        </div>

        <div v-if="pastEvents && pastEvents.length">
          <v-card-title>Completed Events</v-card-title>
          <EventGrid :items="pastEvents" icon="fa-pencil-alt" :minimal="false" class="mx-4 mb-4"></EventGrid>
        </div>
      </div>      
      <br/>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import VueMarkdown from '@/components/VueMarkdown.vue'
import GroupHeader from "@/components/GroupHeader.vue";
import EventGrid from "@/components/EventGrid.vue";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileGroupsView",
  components: {
    EventGrid,
    GroupHeader,
    VueMarkdown,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      org: null,
      events: null,
      status: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getGroup(id) {
      var response = await profileService.getGroup(id);
      this.org = response.data;
      this.events = this.org.events;

      if (this.org == null) {
        this.$router.push('/');
        return;
      }

      var meta = {
        title: this.org.name + ' on ' + tenant.name,
        description: (this.org.description || this.org.name) + ' on ' + tenant.title,
        image: this.org.logo || this.org.img,
      };
      EventBus.$emit('page-header-change', meta);

    },

    async getGroupStatus() {
      if (this.user && this.org) {
        var response = await profileService.getGroupStatus(this.org.id);
        this.status = response.data;

        if (this.status.is_owner && this.$route.query.mode !== 'noRedirect') {
          this.$router.push({ name: 'orgmanagerView', params: { id: this.org.id }, query: { mode: 'redirectFromPublicView' }});
        }
      }
    },

    async getProfile() {
      if (this.user) {
        await this.getGroup(this.$route.params.id);
        await this.getGroupStatus();
      }
    },
  },
  computed: {
    breadcrumbItems() {
      return this.org == null ? [] : [
        { text: this.org.name, disabled: true },
      ]
    },
    activeEvents() {
      return this.events && this.events.filter(x => this.$helpers.isActiveEvent(x)).sort((a,b) => a.from <= b.from);
    },
    upcomingEvents() {
      return this.events && this.events.filter(x => this.$helpers.isFutureEvent(x)).sort((a,b) => a.from <= b.from ? -1 : 1);
    },
    pastEvents() {
      return this.events && this.events.filter(x => this.$helpers.isPastEvent(x)).sort((a,b) => a.from <= b.from ? 1 : -1);
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

